<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div
        class="d-flex align-items-center"
        style="width: 100%"
      >
        <!--        <b-card-title style="flex: 1; white-space: nowrap">Items by Category</b-card-title>-->
        <v-select
          v-if="getCurrentCategories().length > 0"
          v-model="groupSelected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="resolveSubCategories()"
          class="w-100 ml-auto mr-1"
          :reduce="val => val"
          @input="categorySelected = null"
        />
        <v-select
          v-if="groupSelected != null && resolveSubCategories(groupSelected.value) && resolveSubCategories(groupSelected.value).length > 0"
          v-model="categorySelected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="resolveSubCategories(groupSelected.value)"
          class="w-100 ml-auto mr-1"
          :reduce="val => val"
        />
      </div>

    </b-card-header>

    <!-- body -->
    <b-card-body class="scrollable-list">
      <div
        v-for="item in tableDataResolver()"
        :key="item.id"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ item.name.en }}
            </h6>
            <span class="text-muted d-block">
              {{ item.name.it }}
            </span>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ item.items }}</span>
          <span class="font-weight-bold text-body-heading mr-1">{{ resolvePercentage(item.items) + '%' }}</span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BMedia, BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaBody,
    vSelect,
  },
  props: {
    tableData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    resolvePercentage(data) {
      return parseFloat(((100 * data) / this.tableData.total).toFixed(2))
    },
  },
  setup(props) {
    const {
      resolveSubCategories,
      getCurrentCategories,
    } = useCategoriesList()

    const groupSelected = ref(null)
    const categorySelected = ref(null)

    const tableDataResolver = () => {
      if (categorySelected.value) {
        return props.tableData.itemsBySubCategory.filter(subcategory => subcategory.category_id === categorySelected.value.id)
      }
      if (groupSelected.value) {
        return props.tableData.itemsByCategory.filter(category => category.group_id === groupSelected.value.id)
      }
      return props.tableData.itemsByCategory
    }

    return {
      groupSelected,
      categorySelected,
      tableDataResolver,

      getCurrentCategories,
      resolveSubCategories,
    }
  },
}
</script>

<style lang="scss" scoped>
.scrollable-list {
  height: 400px !important;
  overflow-x: auto !important;
  //display: block;
}
</style>
