<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Customers who have download packages</b-card-title>
        <b-card-text class="font-small-2" />
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body class="scrollable-list">
      <div
        v-for="customer in tableData.usersWithActiveDownload"
        :key="customer.id"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ customer.fullName }}
            </h6>
            <span class="text-muted d-block">
              {{ customer.email }}
            </span>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ customer.downloadable_packs }}</span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaBody,
} from 'bootstrap-vue'

export default {
  name: 'UsersWithActiveDownload',

  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaBody,
  },
  props: {
    tableData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.scrollable-list {
  height: 400px !important;
  overflow-x: auto !important;
  //display: block;
}
</style>
