<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Items by Theme</b-card-title>
        <b-card-text class="font-small-2" />
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body class="scrollable-list">
      <div
        v-for="item in tableData.itemsByTheme"
        :key="item.id"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ item.name.en }}
            </h6>
            <span class="text-muted d-block">
              {{ item.name.it }}
            </span>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ item.items }}</span>
          <span class="font-weight-bold text-body-heading mr-1">{{ resolvePercentage(item.items)+'%' }}</span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaBody,
  },
  props: {
    tableData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    resolvePercentage(data) {
      return parseFloat(((100 * data) / this.tableData.total).toFixed(2))
    },
  },
}
</script>

<style lang="scss" scoped>
.scrollable-list {
  height: 400px !important;
  overflow-x: auto !important;
  //display: block;
}
</style>
