<template>
  <!--  <h1>Dashboard</h1>-->
  <section
    v-if="getCurrentCategories().length > 0"
    id="dashboard-ecommerce"
  >
    <b-row class="match-height">
      <b-col
        class="d-block"
        lg="12"
      >
        <item-total
          v-if="Object.keys(getStatistics()).length > 0"
          :data="getStatistics()"
        />
      </b-col>
      <b-col
        class="d-block"
        xl="6"
        md="12"
      >
        <items-by-category-table
          v-if="Object.keys(getStatistics()).length > 0"
          :table-data.sync="getStatistics().items"
        />
      </b-col>
      <b-col
        class="d-block"
        xl="6"
        md="12"
      >
        <items-by-theme-table
          v-if="Object.keys(getStatistics()).length > 0"
          :table-data.sync="getStatistics().items"
        />
      </b-col>
      <b-col
        class="d-block"
        xl="6"
        md="12"
      >
        <items-rent
          v-if="Object.keys(getStatistics()).length > 0"
          :table-data.sync="getStatistics().items"
        />
      </b-col>
      <b-col
        class="d-block"
        xl="6"
        md="12"
      >
        <users-with-active-download
          v-if="Object.keys(getStatistics()).length > 0"
          :table-data.sync="getStatistics().items"
        />
      </b-col>
    </b-row>

    <b-row class="match-height" />
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { isUserLoggedIn } from '@/auth/utils'
import useStatisticsList from '@/views/dashboard/useStatisticsList'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import ItemTotal from '@/views/dashboard/Components/ItemTotal.vue'
import ItemsByCategoryTable from '@/views/dashboard/Components/ItemsByCategoryTable.vue'
import ItemsByThemeTable from '@/views/dashboard/Components/ItemsByThemeTable.vue'
import ItemsRent from '@/views/dashboard/Components/ItemsRent.vue'
import UsersWithActiveDownload from '@/views/dashboard/Components/UsersWithActiveDownloads.vue'

export default {
  components: {
    UsersWithActiveDownload,
    ItemsRent,
    ItemsByThemeTable,
    ItemsByCategoryTable,
    ItemTotal,
    BRow,
    BCol,
  },
  data() {
    return {
      data: {},
    }
  },
  mounted() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  beforeCreate() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  create() {
    if (!isUserLoggedIn()) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  setup() {
    const {
      groupOptions,
      fetchStatistics,
      getStatistics,
    } = useStatisticsList()
    fetchStatistics()

    const {
      fetchCategories,
      getCurrentCategories,
    } = useCategoriesList()
    fetchCategories()

    return {
      groupOptions,
      fetchStatistics,
      getStatistics,
      getCurrentCategories,
    }
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
