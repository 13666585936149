import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isUserLoggedIn } from '@/auth/utils'

export default function useStatisticsList() {
  // Use toast
  const toast = useToast()

  const groupOptions = [
    {
      label: 'Accessories',
      value: 14,
    },
    {
      label: 'Clothing',
      value: 26,
    },
  ]

  const fetchStatistics = (ctx, callback) => {
    if (isUserLoggedIn()) {
      store
        .dispatch('statistics/fetchStatistics', {})
        .then(response => {
          const { statistics } = response.data.data
          if (callback) {
            callback(statistics)
          }
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching statistics',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  const getStatistics = () => store.getters['statistics/getStatistics']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    groupOptions,
    fetchStatistics,
    getStatistics,
  }
}
