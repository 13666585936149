<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Rented Items</b-card-title>
        <b-card-text class="font-small-2" />
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body class="scrollable-list">
      <div
        v-for="item in tableData.itemsRented"
        :key="item.id"
        class="browser-states"
      >
        <div class="media-list">
          <b-media
            vertical-align="top"
          >
            <template #aside>
              <b-img
                :src="item.images.smallww.link"
                blank-color="#ccc"
                width="100"
                alt="placeholder"
              />
            </template>
            <h4 class="media-heading">
              {{ item.code }}<br>
            </h4>
            <h5>
              {{ item.title.en }}
            </h5>
            <b-card-text class="mb-0">
              {{ item.title.it }}
            </b-card-text>
          </b-media>
        </div>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">
            <b-badge
              :variant="rentStatusResolver(item.rent_status)"
              class="badge"
            >
              {{ item.rent_status.replace('_', ' ') }}
            </b-badge>
          </span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BImg, BBadge,
} from 'bootstrap-vue'
import useItemsList from '@/views/items/items/useItemList'

export default {
  name: 'ItemsRent',

  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BImg,
    BBadge,
  },
  props: {
    tableData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {}
  },
  setup() {
    const {
      rentStatusResolver,
    } = useItemsList()
    return {
      rentStatusResolver,
    }
  },
}
</script>

<style lang="scss" scoped>
.scrollable-list {
  height: 400px !important;
  overflow-x: auto !important;
  //display: block;
}
</style>
